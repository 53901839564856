<script setup lang="ts">
import { useRoutes } from '@glow/shared/lib/routes';
import { graphql } from '@/gql';
import CartServiceMasterGroup from './CartServiceMasterGroup.vue';
import { useQuery } from '@urql/vue';
import { useCartId } from '../store/cartId';
import { uniqBy } from 'lodash-es';

const { getUrl } = useRoutes();
const cartUrl = getUrl('cart');
const cartIdStore = useCartId();
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query CartBlock_CartQuery($id: Int) {
      cart(id: $id) {
        id
        cartServices {
          id
          workPlace {
            id
            master {
              id
              ...CartServiceMasterGroup_MasterFragment
            }
          }
          ...CartServiceMasterGroup_CartServiceFragment
        }
      }
    }
  `),
  variables: computed(() => ({
    id: cartIdStore.cartId,
  })),
  pause: toRef(() => !cartIdStore.hasCart),
});
const { staticUrl } = useRuntimeConfig().public;
const cartServices = toRef(() => queryData.value?.cart?.cartServices ?? []);
const masters = toRef(
  () =>
    uniqBy(
      cartServices.value.map((service) => service.workPlace.master),
      (master) => master.id
    ) ?? []
);

function getMasterCartServices(masterId: number) {
  return cartServices.value.filter(
    (service) => service.workPlace.master.id === masterId
  );
}
</script>

<template>
  <div class="cart page-card">
    <NuxtLink :to="cartUrl" class="cart__title text-dark">
      Корзина
      <span class="text-primary">{{ cartServices.length }}</span>
    </NuxtLink>
    <CartServiceMasterGroup
      v-for="master in masters"
      :key="master.id"
      :master="master"
      :cart-services="getMasterCartServices(master.id)"
    />
    <div v-if="!masters.length" class="cart__no-services">
      <NuxtImg
        width="220"
        height="220"
        :src="`${staticUrl}/images/icons/appointment.png`"
      />
      <div>Пока что вы не добавили никаких услуг в корзину</div>
    </div>
    <div class="cart__link-button">
      <AppButton fluid :to="cartUrl">Перейти в корзину</AppButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cart {
  padding: var(--app-spacing-6) var(--app-spacing-2);
  display: flex;
  flex-direction: column;
  gap: var(--app-spacing-5);

  &__title {
    font-size: 24px;
    font-weight: 700;
  }

  &__no-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--app-spacing-3);
    text-align: center;
  }

  &__title,
  &__no-services,
  &__link-button {
    padding: 0 var(--app-spacing-3);
  }
}
</style>
